import React from 'react'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

import { BasicButtonProps } from '@/types/globalTypes'

const BasicButton = ({
    type = 'button',
    label,
    onClick,
    disabled = false,
    className = '',
    icon,
    labelClassName = '',
    children,
    isLoading = false,
    style,
    tooltip,
    tooltipClassName,
    ...rest
}: BasicButtonProps) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
            onClick(e)
        }
    }

    return (
        <button
            type={type}
            onClick={handleClick}
            disabled={disabled || isLoading}
            className={`${className} ${isLoading ? 'btn-disabled' : ''}`}
            style={style}
            data-tooltip-id={tooltip ? 'tooltip' : undefined}
            data-tooltip-content={tooltip}
            data-tooltip-class={tooltipClassName}
            {...rest}
        >
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    {icon}
                    {label ? <span className={labelClassName}>{label}</span> : null}
                    {children}
                </>
            )}
        </button>
    )
}

export default BasicButton
